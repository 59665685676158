<template>
  <div>
    <el-dialog
      title="新增来源"
      :visible.sync="AddFlag"
      :before-close="handleClose"
    >
      <el-form ref="form" :model="form" label-width="100px">
        <!-- //档案类别单选框(radio),购买状态单选框(radio),且都是必填字段 -->
        <el-form-item
          label="来源名称"
          prop="name"
          :label-width="formLabelWidth"
          :required="true"
        >
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item
          label="说明备注"
          prop="remark"
          :label-width="formLabelWidth"
        >
          <el-input v-model="form.remark"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { postBasicConfig } from "@/api/rank/tags";
export default {
  name: "CDMDialogAdd",
  data() {
    return {
      AddFlag: false,
      form: {},
      formLabelWidth: "120px",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  methods: {
    handleClose() {
      this.handleCancel();
    },
    //确定
    handleConfirm() {
      console.log(this);
      let data = {
        name: this.form.name,
        remark: this.form.remark = undefined ? '' : this.form.remark,
        c_type: "from",
      };
      postBasicConfig(data).then((res) => {
        this.$message({
          message: "创建成功",
          type: "success",
        });
        this.AddFlag = false;
        this.$emit("getList");
      });
    },
    handleCancel() {
      this.AddFlag = false;
      this.form.name = "";
      this.form.remark = "";
      this.$emit("getList");
    },
  },
};
</script>

<style lang="scss" scoped></style>
